<template>
    <div>
        <vx-card class="pathway-filter-card" title="Pathways (U&M)">
            <vs-row vs-w="12">
                <vs-col vs-w="11.2" vs-type="flex" vs-justify="flex-start" class="no-padd">
                    <vs-row class="mb-5 mt-6" vs-w="11.2">
                        <vs-col vs-w="1.1" vs-offset="0.1" vs-type="flex" vs-justify="flex-start" vs-align="center">
                            <h6 class="ele-side-head filter">Filters</h6>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="tree-drop"
                            id="city">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                                    City
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedCity" :options="treeDataCity" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="tree-drop"
                            id="gm">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox"
                                    style="text-align: left;">
                                    GM
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect v-model="initSelectedGM" :multiple="true" :options="treeDataGM"
                                        :value-consists-of="'BRANCH_PRIORITY'" valueFormat="object" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="tree-drop"
                            id="spoc">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                                    SPOC
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect :multiple="true" v-model="initSelectedSpoc" :options="treeDataSpoc"
                                        :value-consists-of="'BRANCH_PRIORITY'" valueFormat="object" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5" class="tree-drop"
                            id="elgibility_status">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">Pathway
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown pathway_dropdown" style="z-index: 60000;">
                                    <treeselect v-model="select_pathway" :multiple="true" :options="pathway_options" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-offset="1.2" vs-w="2.5"
                            class="tree-drop mt-12" id="lvl">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                                    Level
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect v-model="selected_level" :options="treeDataLevels" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5"
                            class="tree-drop mt-12" id="eligible"
                            v-if="selectedLevels != null && selectedLevels.toString() == 'CM Level'">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox"
                                    style="text-align: left;">
                                    CM Level
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect v-model="initSelectedCMLevels" :multiple="true" :options="treeDataCMLevels"
                                        :value-consists-of="'LEAF_PRIORITY'" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5"
                            class="tree-drop mt-12" id="eligible"
                            v-if="selectedLevels != null && selectedLevels.toString() == 'USP Level'">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                                    USP Level
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect v-model="initSelectedMaccLevels" :multiple="true"
                                        :value-consists-of="'LEAF_PRIORITY'" :options="treeDataMaccLevels" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" class="none_padding mt-6"
                            vs-w="2.5">
                            <div style="display: inline-grid; width: 91%;">
                                <label class="df_heading">Next Call</label>
                                <datepicker placeholder="DD/MM/YY" class="my_date_picker" :format="customFormatter"
                                    v-model="selected_date"></datepicker>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.7">
                            <div style="height:10px;margin-top:40%">
                                <vs-button color="#0044ba" icon="clear" @click="clearDateFilter" size="small"></vs-button>
                            </div>
                        </vs-col>
                    </vs-row>
                </vs-col>
                <vs-col vs-w="11.2" vs-type="flex" vs-justify="flex-start" class="no-padd">

                    <vs-row class="my-3">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1" vs-w="11.7">
                            <vs-row class="criteria">
                                <vs-row>
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1"
                                        vs-w="11.7">
                                        <h6 class="criteria_heading">Search Criteria:</h6>
                                    </vs-col>
                                </vs-row>
                                <vs-row vs-w="12">
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1"
                                        vs-w="10.7">
                                        <div style="display: block; width: 100%">
                                            <span v-for="chip in cityData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'city')" color="#EAE5FA">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selectedGMData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'gm')" color="#D5FFF5">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selectedSpocData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'spoc')" color="#C3EDFA">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>

                                            <span v-if="selectedLevels != null">
                                                <vs-chip class="criteria_chip" closable
                                                    @click="removechip(selectedLevels.toString(), 'level')" color="#D5FFD9">
                                                    <b>{{ selectedLevels.toString() }}</b>
                                                </vs-chip>
                                            </span>

                                            <span v-for="chip in selected_cm_levels" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'cm')" color="#f1ad78">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>

                                            <span v-for="chip in selected_macc_levels" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'macc')" color="rgba(255, 194, 194, 0.69)">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="pathway in selected_pathway_data" :key="pathway">
                                                <vs-chip v-if="pathway !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(pathway, 'pathway')" color="#f1ad78">
                                                    <b>{{ pathway }}</b>
                                                </vs-chip>
                                            </span>
                                        </div>
                                    </vs-col>
                                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="1.2">
                                        <vs-button color="#0044ba" icon="search" @click="getSpocIds()"
                                            size="small"></vs-button> &nbsp;
                                        <vs-button color="#0044ba" icon="refresh" @click="refreshPathwayData()"
                                            size="small"></vs-button>
                                    </vs-col>
                                </vs-row>
                            </vs-row>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card class="mt-4">
            <vs-table :data="tableRows" style="margin-top: 3%;border-top: 1px solid;border-left: 1px solid;color: black;">
                <template slot="thead">
                    <vs-th :key="index" v-for="(head, index) in tableHeaders" :colspan="head.colspan"
                        :style="{ borderRight: '1px solid' }">{{ head.header }}</vs-th>
                </template>
                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" :style="tr.style">
                        <vs-td>{{ tr.overall }}</vs-td>
                        <vs-td style="border: 1px solid">{{ tr.level }}</vs-td>
                        <vs-td style="border: 1px solid">
                            <div
                                @click="getPathwayType(tr.type), preFlightFetch(tr.level === '' ? tr.overall : tr.level, 'allocated', selected_city_classification)">
                                {{ tr.city.allocated }}
                            </div>
                        </vs-td>
                        <vs-td style="border: 1px solid">
                            <div
                                @click="getPathwayType(tr.type), preFlightFetch(tr.level === '' ? tr.overall : tr.level, 'delays', selected_city_classification)">
                                {{ tr.city.delays }}
                            </div>
                        </vs-td>
                        <vs-td v-for="(spoc, spocIndex) in tr.dynamicSpoc" :key="spocIndex" style="border: 1px solid">
                            <div @click="getPathwayType(tr.type), preFlightFetch(tr.level === '' ? tr.overall : tr.level, data[0].dynamicSpoc[spocIndex],
                                    selected_city_classification, [tr, spocIndex])">
                                {{ spoc }}
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>
    </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import vSelect from "vue-select";
import constants from "../../constants.json";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Datepicker from "vuejs-datepicker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "vue-select/dist/vue-select.css";
import eventbus from '../components/eventbus';
export default {
    components: {
        Treeselect,
        "v-select": vSelect,
        Datepicker
    },
    data: () => ({
        pathway_type: null,
        cities: [],
        spoc_names: [],
        tableHeaders: [],
        tablerowConstant: [],
        tableRows: [],
        selected_date: new Date(),
        initSelectedCity: null,
        initSelectedGM: [],
        initSelectedSpoc: [],
        initSelectedCMLevels: [],
        initSelectedMaccLevels: [],
        cityData: [],
        selectedGMData: [],
        selectedSpocData: [],
        selected_cm_levels: [],
        selected_macc_levels: [],
        user_with_gms: [],
        treeDataCity: [],
        treeDataGM: [],
        treeDataSpoc: [],
        treeDataCMLevels: [
            {
                id: "All",
                label: "All",
                children: [
                    {
                        id: "P1",
                        label: "P1",
                        children: [
                            {
                                id: "M3",
                                label: "M3",
                            },
                            {
                                id: "M3+",
                                label: "M3+",
                            },
                            {
                                id: "M3++",
                                label: "M3++",
                            },
                            {
                                id: "M5",
                                label: "M5",
                            },
                            {
                                id: "M6",
                                label: "M6",
                            },
                        ],
                    },
                    {
                        id: "P2",
                        label: "P2",
                        children: [
                            {
                                id: "M4",
                                label: "M4",
                            },
                            {
                                id: "M2",
                                label: "M2",
                            },
                        ],
                    },
                    {
                        id: "P3",
                        label: "P3",
                        children: [
                            {
                                id: "M4-",
                                label: "M4-",
                            },
                            {
                                id: "M1",
                                label: "M1",
                            },
                        ],
                    },
                    {
                        id: "ENROLLED",
                        label: "ENROLLED",
                        children: [
                            {
                                id: "M7",
                                label: "M7",
                            }]
                    }
                ],
            },
        ],
        treeDataMaccLevels: [
            {
                id: "All",
                label: "All",
                children: [
                    { id: "No U level", label: "No U Level" },
                    { id: "U0--", label: "U0--" },
                    { id: "U0", label: "U0" },
                    { id: "U1", label: "U1" },
                    { id: "U1+", label: "U1+" },
                    { id: "U2", label: "U2" },
                    { id: "U3-", label: "U3-" },
                    { id: "U3", label: "U3" },
                    { id: "U3+", label: "U3+" },
                    { id: "U3++", label: "U3++"},
                    { id: "U4-", label: "U4-" },
                    { id: "U4", label: "U4" },
                    { id: "U4R", label: "U4R" },
                    { id: "U5", label: "U5" },
                    { id: "U5+", label: "U5+" },
                    { id: "U6-", label: "U6-" },
                    { id: "U6", label: "U6" },
                    { id: "U6+", label: "U6+" },
                    { id: "U7-", label: "U7-" },
                    { id: "U7", label: "U7" },
                    { id: "U8", label: "U8" },
                    { id: "U9", label: "U9" },
                    { id: "U10", label: "U10" },
                ]
            }
        ],
        pathway_options: [
            {
                id: "YES",
                label: "Yes",
            },
            {
                id: "NO",
                label: "No",
            },
            {
                id: "MAY BE",
                label: "May be",
            }
        ],
        select_pathway: [],
        spoc_ids: [],
        selected_pathway_data: [],
        selected_city_classification: '',
        selected_level: null,
        selectedLevels: null,
        levels: { 'CM Level': 'm_level', 'USP Level': 'u_level' },
        treeDataLevels: [
            { id: "CM Level", label: "CM Level" },
            { id: "USP Level", label: "USP Level" }
        ]
    }),
    mounted() {
        this.selected_level = ['CM Level']
        this.getUsersWithGMs('onload')
        eventbus.$on("updatePathway", () => {
            console.log('popup closed');
        });
    },
    watch: {
        initSelectedCity: function (val) {
            if (val != null) {
                this.getDropdownGM(val)
                this.getDropdownSpoc(val)
            } else {
                this.treeDataGM = []
                this.treeDataSpoc = []
                this.initSelectedGM = []
                this.initSelectedSpoc = []
            }
            this.mergeAllSearch()
        },
        initSelectedGM: function (val) {
            this.initSelectedSpoc = []
            this.treeDataSpoc = []
            if (val.length != 0 && !val[0].hasOwnProperty('children')) {
                this.getUsersWithGMs()
                let user_with_gms = []
                this.user_with_gms.forEach(gm => {
                    val.forEach(ele => {
                        if (ele.label == gm.gm && ele.city == gm.city) {
                            user_with_gms.push(gm)
                        }
                    });
                });
                this.user_with_gms = user_with_gms
                this.getDropdownSpoc(this.initSelectedCity)
            } else {
                this.getUsersWithGMs()
                this.getDropdownSpoc(this.initSelectedCity)
            }
            this.mergeAllSearch()
        },
        initSelectedSpoc: function () {
            this.mergeAllSearch()
        },
        selected_level: function () {
            this.mergeAllSearch()
        },
        initSelectedCMLevels: function () {
            this.mergeAllSearch()
        },
        initSelectedMaccLevels: function () {
            this.mergeAllSearch()
        },
        select_pathway: function () {
            this.mergeAllSearch();
        }
    },
    methods: {
        preFlightFetch(level, type, selected_city_classification, spoc) {
            let city_classification = selected_city_classification;
            if (type === "Allocated" || type === "Delays") {
                let newtype = this.convertType(type);
                let spocname = this.mapSearchSpoc(spoc);
                this.fetchSelectedData(level, newtype, city_classification, spocname);
            } else {
                this.fetchSelectedData(level, type, city_classification, '0');
            }
        },
        getPathwayType(type) {
            if (type != '' && type != null) {
                this.pathway_type = type.replace(/ total/g, "");
            }
        },
        getParamsForPathway(lvl, type, spoc_id) {
            console.log("reee", lvl, spoc_id);
            let obj = {}
            let pathways = ['YES', 'NO', 'MAY BE']
            let cm_level_yes_no_maybe = ['M1', 'M2', 'M3', 'M3+', 'M3++', 'M4', 'M4-', 'M5', 'M6', 'M7'];
            let macc_level_yes_no_maybe = ['No U level', 'U0', 'U1', 'U1+','U2','U3-','U3', 'U3+','U3++','U4-','U4','U4R','U5','U5+','U6-','U6','U6+','U7-','U7','U8','U9','U10' ];
            // if (spoc_id == 0 && lvl == 'Grand Total') {
            //     obj['level'] = 'total';
            //     obj['pathway'] = 'total';
            //     obj['type'] = type;
            //     obj['spoc_id'] = this.spoc_ids
            // }
            if (spoc_id == 0 && pathways.includes(lvl)&& this.selected_level == 'CM Level' && this.selected_cm_levels.length != 0) {
                obj['level'] = this.selected_cm_levels.toString();
                obj['pathway'] = lvl;
                obj['type'] = type;
                obj['spoc_id'] = this.spoc_ids
            }else if (spoc_id == 0 && pathways.includes(lvl)&& this.selected_level == 'USP Level' && this.selected_macc_levels.length != 0) {
                obj['level'] = this.selected_macc_levels.toString();
                obj['pathway'] = lvl;
                obj['type'] = type;
                obj['spoc_id'] = this.spoc_ids
            }else if (spoc_id == 0 && lvl != 'Grand Total' && pathways.includes(lvl) && this.selected_level == 'CM Level') {
                obj['level'] = cm_level_yes_no_maybe.toString();
                obj['pathway'] = lvl;
                obj['type'] = type
                obj['spoc_id'] = this.spoc_ids
            }
            else if (spoc_id == 0 && lvl != 'Grand Total' && pathways.includes(lvl) && this.selected_level == 'USP Level') {
                obj['level'] = macc_level_yes_no_maybe.toString();
                obj['pathway'] = lvl;
                obj['type'] = type
                obj['spoc_id'] = this.spoc_ids
            }
            // else if (spoc_id == 0 && lvl != 'Grand Total' && !pathways.includes(lvl)) {
            //     obj['level'] = lvl;
            //     obj['pathway'] = this.pathway_type;
            //     obj['type'] = type
            //     obj['spoc_id'] = this.spoc_ids
            // }
            if (spoc_id == 0 && lvl == 'Grand Total' && this.selected_level == 'CM Level' && this.selected_cm_levels.length != 0) {
                obj['level'] = this.selected_cm_levels.toString();
                obj['pathway'] = 'total';
                obj['type'] = type;
                obj['spoc_id'] = this.spoc_ids
            }else if (spoc_id == 0 && lvl == 'Grand Total' && this.selected_level == 'USP Level' && this.selected_macc_levels.length != 0) {
                obj['level'] = this.selected_macc_levels.toString();
                obj['pathway'] = 'total';
                obj['type'] = type;
                obj['spoc_id'] = this.spoc_ids
            } else if (spoc_id == 0 && lvl == 'Grand Total' && this.selected_level == 'CM Level') {
                obj['level'] = cm_level_yes_no_maybe.toString();
                obj['pathway'] = 'total';
                obj['type'] = type;
                obj['spoc_id'] = this.spoc_ids
            } else if (spoc_id == 0 && lvl == 'Grand Total' && this.selected_level == 'USP Level') {
                obj['level'] = macc_level_yes_no_maybe.toString();
                obj['pathway'] = 'total';
                obj['type'] = type;
                obj['spoc_id'] = this.spoc_ids
            } 
            else if (spoc_id != 0 && lvl == 'Grand Total' && this.selected_level == 'CM Level' && this.selected_cm_levels.length != 0) {
                obj['level'] = this.selected_cm_levels.toString();
                obj['pathway'] = 'total';
                obj['type'] = type;
            } 
            else if (spoc_id != 0 && lvl == 'Grand Total' && this.selected_level == 'USP Level' && this.selected_macc_levels.length != 0) {
                obj['level'] = this.selected_macc_levels.toString();
                obj['pathway'] = 'total';
                obj['type'] = type;
            } 
            else if (spoc_id != 0 && lvl == 'Grand Total' && this.selected_level == 'CM Level') {
                obj['level'] = cm_level_yes_no_maybe.toString();
                obj['pathway'] = 'total';
                obj['type'] = type;
            }
            else if (spoc_id != 0 && lvl == 'Grand Total' && this.selected_level == 'USP Level') {
                obj['level'] = macc_level_yes_no_maybe.toString();
                obj['pathway'] = 'total';
                obj['type'] = type;
            }

            // else if (spoc_id != 0 && pathways.includes(lvl)) {
            //     obj['level'] = 'total';
            //     obj['pathway'] = lvl;
            //     obj['type'] = type;
            // } 
            else if (spoc_id != 0 && lvl != 'Grand Total' && !pathways.includes(lvl)) {
                obj['level'] = lvl;
                obj['pathway'] = this.pathway_type;
                obj['type'] = type;
            }
            else if (spoc_id == 0 && lvl != 'Grand Total' && !pathways.includes(lvl)) {
                obj['level'] = lvl;
                obj['pathway'] = this.pathway_type;
                obj['type'] = type;
                obj['spoc_id'] = this.spoc_ids
            }
            else if (spoc_id != 0 && pathways.includes(lvl) && this.selected_level == 'CM Level' && this.selected_cm_levels.length != 0) {
                obj['level'] = this.selected_cm_levels.toString();
                obj['pathway'] = lvl;
                obj['type'] = type;
            }
            else if (spoc_id != 0 && pathways.includes(lvl) && this.selected_level == 'USP Level' && this.selected_macc_levels.length != 0) {
                obj['level'] = this.selected_macc_levels.toString();
                obj['pathway'] = lvl;
                obj['type'] = type;
            } 
            else if (spoc_id != 0 && pathways.includes(lvl) && this.selected_level == 'CM Level') {
                obj['level'] = cm_level_yes_no_maybe.toString();
                obj['pathway'] = lvl;
                obj['type'] = type;
            }
            else if (spoc_id != 0 && pathways.includes(lvl) && this.selected_level == 'USP Level') {
                obj['level'] = macc_level_yes_no_maybe.toString();
                obj['pathway'] = lvl;
                obj['type'] = type;
            }  

            // if (this.selected_macc_levels.length != 0) {
            //     obj['level'] = this.selected_macc_levels.toString();
            // } else if (this.selected_cm_levels.length != 0) {
            //     obj['level'] = this.selected_cm_levels.toString();
            // }
            if (this.selected_pathway_data.length != 0 && !pathways.includes(lvl) && !cm_level_yes_no_maybe.includes(lvl) && !macc_level_yes_no_maybe.includes(lvl)) {
                obj['pathway'] = this.selected_pathway_data.toString();
            }
            return obj;
        },
        fetchSelectedData(level, type, city_classification, placement) {
            let popupName;
            popupName = `${level} - ${city_classification.toUpperCase()} - ${type.toUpperCase()}`;
            if (placement != 0) {
                popupName = `${level} - ${placement.toUpperCase()} - ${type.toUpperCase()}`;
            }
            console.log(popupName);
            this.spoc_names.forEach((spoc) => {
                if (spoc.full_name === placement) {
                    placement = spoc.id;
                }
            });

            if (this.selected_date == null) {
                this.$vs.notify({
                    title: "Select a Date",
                    text: "Select a date to continue using this feature",
                    color: "warning",
                });
            } else if (this.selected_level == null) {
                this.$vs.notify({
                    title: "Select a Level",
                    text: "Select a level to continue using this feature",
                    color: "warning",
                });
            } else {
                this.$vs.loading();
                let url = `${constants.MILES_GOOGLE_INVITE}getPathwayLevelContactDetails`;
                let obj = this.getParamsForPathway(level, type, placement)
                obj['selected_date'] = this.datatoTimestamp(this.selected_date);
                if (Number.isInteger(placement)) {
                    obj['spoc_id'] = placement;
                }
                if (this.selected_level == null) {
                    obj['levels'] = 'm_level'
                } else {
                    obj['levels'] = this.levels[this.selected_level.toString()]
                }
                axios.get(url, {
                    params: obj,
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                }).then((response) => {
                    // if(response.data.status == 'success'){
                    response.data.data.forEach((lead) => {
                        lead.engagements = [];
                        lead.mobile = [];
                        lead.email = [];
                    });
                    this.$store.commit("MOUNT_MWB_LEADS", response.data.data);

                    let popupcount = response.data.total;
                    eventbus.$emit("open-pathwayum-details", [
                        obj,
                        url,
                        response.data,
                        "getPathwayLevelContactDetails",
                        popupName + "-" + popupcount,
                    ]);
                    console.log("getDelaysByLevelContactDetailsForPathway", response);
                    this.$vs.loading.close()
                })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }
        },
        getDelaysByPathwayLevel() {
            let url = `${constants.MILES_GOOGLE_INVITE}getPathwayWithLevel`;
            let obj = {
                spoc_ids: this.spoc_ids,
                pathway: this.selected_pathway_data.toString()
            }
            if (this.selected_level == null) {
                obj['levels'] = 'm_level'
            } else {
                obj['levels'] = this.levels[this.selected_level.toString()]
            }
            if (this.selected_pathway_data.length == 0) {
                obj.pathway = 'YES,NO,MAY BE'
            }
            obj['selected_date'] = this.datatoTimestamp(this.selected_date);
            console.log(obj);
            this.$vs.loading();
            const httpClient = axios.create();
            httpClient.defaults.timeout = 1000 * 85;
            httpClient.get(url, {
                params: obj,
                headers: {
                    Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
            })
                .then((response) => {
                    if (response.data.length != 0) {
                        let levels = []
                        response.data.forEach(ele => {
                            ele.level_data.forEach(level => {
                                ele[Object.keys(level)[0]] = Object.assign({}, ...Object.values(level)[0]);
                                levels = Object.keys(ele[Object.keys(level)[0]])
                                delete ele.level_data
                            });
                        });
                        this.getTableDesign(levels)
                        this.generateTableHeaders(response.data)
                        this.generateTableRows(response.data)
                    }
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    if (error.code === 'ECONNABORTED') {
                        console.error('Request timed out.');
                    } else {
                        this.handleError(error);
                    }
                    this.$vs.loading.close();
                });

        },
        getTableDesign(lvls) {
            if (this.initSelectedCMLevels.length != 0) {
                lvls = this.initSelectedCMLevels
            } else if (this.initSelectedMaccLevels.length != 0) {
                lvls = this.initSelectedMaccLevels
            }
            this.tablerowConstant = [
                {
                    style: { borderTop: "1px solid", borderBottom: "1px solid" },
                    overall: "",
                    level: "",
                },
                {
                    style: { borderBottom: "1px solid", background: "#FFCCCC" },
                    overall: "Grand Total",
                    level: "",
                },

            ]
            let levels = lvls
            let pathways = [{ name: 'YES', color: '#CCFFCC', total: '#93C47D' }, { name: 'NO', color: '#CCFFFF', total: '#A4C2F4' }, { name: 'MAY BE', color: '#FFF2CC', total: '#FFD966' }]
            if (this.selected_pathway_data.length != []) {
                let new_pathways = []
                this.selected_pathway_data.forEach(path => {
                    if (path) {
                        new_pathways.push(pathways.filter((item) => item.name == path)[0])
                    }
                });
                pathways = new_pathways
            }
            pathways.forEach(pathway => {
                for (let i = 0; i < levels.length; i++) {
                    const level = levels[i];
                    let obj = {
                        style: { borderBottom: "0px solid", background: `${pathway.color}` },
                        type: pathway.name,
                        'level': level,
                    }
                    let array_len = levels.length / 2
                    if (i == Math.round(array_len)) {
                        obj['overall'] = `${pathway.name}`
                    }
                    this.tablerowConstant.push(obj)
                }
                let total_obj = {
                    style: { borderBottom: "1px solid", borderTop: "1px solid", background: `${pathway.total}` },
                    overall: 'Total',
                    type: `${pathway.name} Total`,
                    'level': `${pathway.name}`
                }
                this.tablerowConstant.push(total_obj)
            });
            this.tableRows = this.tablerowConstant
        },
        generateTableRows(response) {
            this.tableRows[0].dynamicSpoc = [];
            this.tableRows[0].city = {
                allocated: "Allocated",
                delays: "Delays",
            };
            this.tableRows.forEach((row, index) => {
                if (index !== 0) {
                    row.dynamicSpoc = [];
                    row.city = {
                        allocated: 0,
                        delays: 0,
                    };
                }
            });

            //Individual totals of P1 P2 P3 
            response.forEach(() => {
                let allocated = 0;
                let delays = 0;
                this.tableRows[0].dynamicSpoc.push("Allocated");
                this.tableRows[0].dynamicSpoc.push("Delays");
                this.tableRows.forEach((row, index) => {
                    if (index !== 0) {
                        row.dynamicSpoc.push(allocated);
                        row.dynamicSpoc.push(delays);
                    }
                });
            });
            this.InitialCalculation(response);
        },
        generateTableHeaders(response) {
            let lvl = this.selected_level.toString()
            this.tableHeaders = [];
            this.tableHeaders.push({
                header: "OverAll",
                colspan: 1,
                fixed: true,
            });
            this.tableHeaders.push({
                header: lvl,
                colspan: 1,
                fixed: true,
            });
            // this.tableHeaders.push({
            //     header: "U Level",
            //     colspan: 1,
            //     fixed: true,
            // });
            let city = this.initSelectedCity
            this.selected_city_classification = city
            this.tableHeaders.push({
                header: city.replace(/_/g, " "),
                colspan: 2,
            });
            response.forEach(spoc => {
                let obj = {
                    header: spoc.spoc_name,
                    colspan: 2,
                };
                this.tableHeaders.push(obj);
            });
        },
        InitialCalculation(response) {
            this.tableRows.forEach((row, index) => {
                if (index > 1 && index !== this.tableRows.length - 1) {
                    let cityallocated = 0;
                    // let citycontacted = 0;
                    let citydelays = 0;
                    row.dynamicSpoc = [];
                    response.forEach((spoc) => {
                        for (var key in spoc) {
                            if (spoc.hasOwnProperty(key)) {
                                if (key !== "NA" && key !== "spoc_id" && key !== "spoc_name" && key !== 'spoc_city') {
                                    for (var key2 in spoc[key]) {
                                        if (spoc[key].hasOwnProperty(key2)) {
                                            if (key2 === row.level && key == row.type) {
                                                //city total column calculation
                                                cityallocated =
                                                    cityallocated + spoc[key][key2].allocated;
                                                citydelays = citydelays + spoc[key][key2].delays;
                                                //individual calculation
                                                row.dynamicSpoc.push(spoc[key][key2].allocated);
                                                row.dynamicSpoc.push(spoc[key][key2].delays);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });
                    //city total column calculation
                    row.city.allocated = cityallocated; //Row totals
                    row.city.delays = citydelays; //Row totals

                }
            });
            let totalcityallocated = 0;
            let totalcitydelays = 0;
            let dynamicarr = [];

            this.tableRows.forEach((row, index) => {
                if (index === 1) {
                    dynamicarr = row.dynamicSpoc;
                } else if (index > 2) {
                    totalcityallocated = totalcityallocated + row.city.allocated;
                    totalcitydelays = totalcitydelays + row.city.delays;
                    row.dynamicSpoc.forEach((spocStat, index2) => {
                        dynamicarr[index2] = parseInt(spocStat);
                    });
                }
            });
            this.tableRows[1].city.allocated = totalcityallocated;
            this.tableRows[1].city.delays = totalcitydelays;
            this.tableRows[1].dynamicSpoc = dynamicarr;

            this.calculateM7();
            this.calculateTotals();
            this.$vs.loading.close();
        },
        calculateM7() {
            let totalcityallocated = 0;
            let totalcitydelays = 0;
            let dynamicarr = [];
            this.tableRows.forEach((row, index) => {
                if (index === 1) {
                    dynamicarr = row.dynamicSpoc;
                } else if (index > 1) {
                    totalcityallocated = totalcityallocated + row.city.allocated;
                    totalcitydelays = totalcitydelays + row.city.delays;
                    row.dynamicSpoc.forEach((spocStat, index2) => {
                        dynamicarr[index2] =
                            parseInt(dynamicarr[index2]) + parseInt(spocStat);
                    });
                }
            });
            this.tableRows[1].city.allocated = totalcityallocated;
            this.tableRows[1].city.delays = totalcitydelays;
            this.tableRows[1].dynamicSpoc = dynamicarr;
        },
        calculateTotals() {
            let parts = ['YES', 'NO', 'MAY BE'];
            parts.forEach((eachPart) => {
                let cityAllocated = 0;
                let cityDelays = 0;
                let dynamicSpocValues = [];
                this.tableRows[0].dynamicSpoc.forEach(() => {
                    dynamicSpocValues.push(0);
                });
                this.tableRows.forEach((tableRow) => {
                    if (tableRow.type == eachPart) {
                        cityAllocated =
                            parseInt(cityAllocated) + parseInt(tableRow.city.allocated);
                        cityDelays = parseInt(cityDelays) + parseInt(tableRow.city.delays);
                        tableRow.dynamicSpoc.forEach((value, index) => {
                            dynamicSpocValues[index] =
                                parseInt(dynamicSpocValues[index]) + parseInt(value);
                        });
                    } else if (tableRow.type == `${eachPart} Total`) {
                        tableRow.city.allocated = cityAllocated;
                        tableRow.city.delays = cityDelays;
                        tableRow.dynamicSpoc = dynamicSpocValues;
                    }
                });
            });
        },
        refreshPathwayData() {
            this.initSelectedCity = null
            this.initSelectedGM = []
            this.initSelectedSpoc = []
            this.initSelectedCMLevels = []
            this.initSelectedMaccLevels = []
            this.select_pathway = []
            this.cityData = []
            this.selectedGMData = []
            this.selectedSpocData = []
            this.selected_pathway_data = []
            this.selected_cm_levels = []
            this.selected_macc_levels = []
            this.selected_date = null
            this.getUsersWithGMs('onload')
        },
        getUsersWithGMs(city) {
            let url = `${constants.SERVER_API}getUsersWithGMs`;
            axios.get(url, {
                headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
            }).then((response) => {
                let logged_in_user = localStorage.getItem('user_id')
                let team = localStorage.getItem('team')
                if (team == 'CM' || team == 'SR' || team == 'ExEd') {
                    response.data.forEach(ele => {
                        if (logged_in_user == ele.id) {
                            this.user_with_gms = []
                            this.user_with_gms.push(ele);
                        }
                    });
                } else if (localStorage.getItem('team') == 'GM') {
                    this.getGmReportingSpocs(response.data, this.getDropdownCity)
                } else {
                    this.user_with_gms = response.data
                }
                if (city != undefined) {
                    this.getDropdownCity()
                }

            })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getGmReportingSpocs(res, callback = () => { }) {
            let gm_id = localStorage.getItem("spoc_id");
            let ids = [];
            let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    for (let i = 0; i < response.data.spocs.length; i++) {
                        const element = response.data.spocs[i];
                        ids.push(element.id);
                    }
                    this.gm_ids = ids
                    this.user_with_gms = []
                    this.gm_ids.forEach(gm => {
                        res.forEach(ele => {
                            if (ele.id == gm) {
                                this.user_with_gms.push(ele);
                            }
                        });
                    });
                    return callback();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getDropdownCity() {
            this.treeDataCity = []
            let uniqe_city = [...new Set(this.user_with_gms.map((item) => item.city))].sort();
            uniqe_city.forEach(element => {
                let obj = {
                    id: element,
                    label: element,
                }
                this.treeDataCity.push(obj);
            })
        },
        getDropdownGM(val) {
            this.treeDataGM = []
            let uniqe_city = [...new Set(this.user_with_gms.map((item) => item.city))].sort();
            uniqe_city.forEach(element => {
                let obj = {
                    id: element,
                    label: element,
                    children: []
                }
                if (val == element) {
                    this.treeDataGM.push(obj);
                }
            })
            this.user_with_gms.forEach((element) => {
                this.treeDataGM.forEach((city) => {
                    if (element.city === city.label) {
                        if (element.gm !== "No GM") {
                            let gm_set = new Set(city.children.map((item) => item.label));
                            if (!gm_set.has(element.gm)) {
                                let gm_obj = {
                                    city: element.city,
                                    id: element.city + "_" + element.gm,
                                    label: element.gm
                                }
                                city.children.push(gm_obj);
                            }
                        }
                    }
                })
            });
        },
        getDropdownSpoc(val) {
            let treeDataSpoc = []
            let uniqe_city = [...new Set(this.user_with_gms.map((item) => item.city))].sort();
            uniqe_city.forEach(element => {
                let obj = {
                    id: element,
                    label: element,
                    children: []
                }
                if (val == element) {
                    treeDataSpoc.push(obj);
                }
            })
            let duplicates = []
            this.user_with_gms.forEach((element) => {
                treeDataSpoc.forEach((city) => {
                    if (element.city === city.label) {
                        let gm_set = new Set(city.children.map((item) => item.label));
                        if (!gm_set.has(element.last_name) && !duplicates.includes(element.first_name)) {
                            duplicates.push(element.first_name)
                            let spoc_obj = {
                                city: element.city,
                                id: element.city + "_" + element.first_name + " " + element.last_name,
                                label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                        }
                    }
                })
            });
            this.treeDataSpoc = treeDataSpoc
        },
        getSpocIds() {
            let spoc_ids = []
            this.user_with_gms.forEach(raw => {
                if (this.initSelectedSpoc.length != 0 && this.initSelectedCity != null) {
                    this.initSelectedSpoc.forEach(spoc => {
                        if (spoc.label == raw.first_name + " " + raw.last_name) {
                            spoc_ids.push(raw.id)
                            let obj = {
                                full_name: raw.first_name + " " + raw.last_name,
                                id: raw.id
                            }
                            this.spoc_names.push(obj)
                        }
                    });
                } else if (this.initSelectedCity != null) {
                    this.treeDataSpoc[0].children.forEach(spoc => {
                        if (spoc.label == raw.first_name + " " + raw.last_name) {
                            spoc_ids.push(raw.id)
                            let obj = {
                                full_name: raw.first_name + " " + raw.last_name,
                                id: raw.id
                            }
                            this.spoc_names.push(obj)
                        }
                    });
                }
            });
            this.spoc_ids = spoc_ids.toString()
            if (this.initSelectedCity != null) {
                if (this.selected_date != null) {
                    this.getDelaysByPathwayLevel();
                } else {
                    this.$vs.notify({
                        title: "Select a Date",
                        text: "Select a date to continue using this feature",
                        color: "warning",
                    });
                }
            } else {
                this.$vs.notify({
                    title: "Select a City",
                    text: "Select a city to continue using this feature",
                    color: "warning",
                });
            }
            console.log("spoc Ids", this.spoc_ids)
        },
        mergeAllSearch() {
            this.cityData = [];
            this.cities = []
            if (this.initSelectedCity != null) {
                this.cityData.push(this.initSelectedCity);
                this.cities.push(this.initSelectedCity)
            }
            this.selectedGMData = [];
            this.initSelectedGM.forEach((sort) => {
                this.selectedGMData.push(sort.label);
            });

            this.selectedSpocData = [];
            this.initSelectedSpoc.forEach((sort) => {
                this.selectedSpocData.push(sort.label);
            });

            this.selectedLevels = null;
            this.selectedLevels = this.selected_level

            this.selected_cm_levels = [];
            this.initSelectedCMLevels.forEach((sort) => {
                console.log("initSelectedCMLevels element", sort);
                if (sort === "All") {
                    this.treeDataCMLevels[0].children[0].children.forEach((child) => {
                        this.selected_cm_levels.push(child.label);
                    });
                    this.treeDataCMLevels[0].children[1].children.forEach((child) => {
                        this.selected_cm_levels.push(child.label);
                    });
                    this.treeDataCMLevels[0].children[2].children.forEach((child) => {
                        this.selected_cm_levels.push(child.label);
                    });
                    this.treeDataCMLevels[0].children[3].children.forEach((child) => {
                        this.selected_cm_levels.push(child.label);
                    });
                    this.treeDataCMLevels[0].children[4].children.forEach((child) => {
                        this.selected_cm_levels.push(child.label);
                    });
                } else if (sort === "P1") {
                    this.treeDataCMLevels[0].children[0].children.forEach((child) => {
                        this.selected_cm_levels.push(child.label);
                    });
                } else if (sort === "P2") {
                    this.treeDataCMLevels[0].children[1].children.forEach((child) => {
                        this.selected_cm_levels.push(child.label);
                    });
                } else if (sort === "P3") {
                    this.treeDataCMLevels[0].children[2].children.forEach((child) => {
                        this.selected_cm_levels.push(child.label);
                    });
                } else if (sort === "ENROLLED") {
                    this.treeDataCMLevels[0].children[3].children.forEach((child) => {
                        this.selected_cm_levels.push(child.label);
                    });
                }
                else if (sort === "Dnd") {
                    this.treeDataCMLevels[0].children[4].children.forEach((child) => {
                        this.selected_cm_levels.push(child.label);
                    });
                } else {
                    this.selected_cm_levels.push(sort);
                }
            });

            this.selected_macc_levels = [];
            this.initSelectedMaccLevels.forEach((sort) => {
                if (sort === "All") {
                    this.treeDataMaccLevels[0].children.forEach((child) => {
                        this.selected_macc_levels.push(child.label);
                    });
                } else {
                    this.selected_macc_levels.push(sort);
                }
            });

            this.selected_pathway_data = [];
            this.select_pathway.forEach((sort) => {
                this.selected_pathway_data.push(sort);
            });
        },
        removechip(chip, name) {
            if (name == "city") {
                this.cityData = []
                this.initSelectedCity = null
            }

            if (name == "gm") {
                this.selectedGMData.splice(this.selectedGMData.indexOf(chip), 1);
                let index = this.initSelectedGM.findIndex(x => x.label === chip);
                this.initSelectedGM.splice(index, 1);
            }

            if (name == "spoc") {
                this.selectedSpocData.splice(this.selectedSpocData.indexOf(chip), 1);
                let index = this.initSelectedSpoc.findIndex(x => x.label === chip);
                this.initSelectedSpoc.splice(index, 1);
            }
            if (name == 'level') {
                this.selected_level = null,
                    this.selectedLevels = null
            }

            if (name == "cm") {
                this.selected_cm_levels.splice(this.selected_cm_levels.indexOf(chip), 1);
                if (this.initSelectedCMLevels.indexOf(chip) !== -1) {
                    this.initSelectedCMLevels.splice(
                        this.initSelectedCMLevels.indexOf(chip),
                        1
                    );
                } else {
                    let childProperty = "";
                    this.treeDataCMLevels[0].children.forEach((child) => {
                        if ("children" in child) {
                            child.children.forEach((chi) => {
                                if (chi.label === chip) {
                                    childProperty = child.label;
                                }
                            });
                        }
                    });
                    this.initSelectedCMLevels.splice(
                        this.initSelectedCMLevels.indexOf(childProperty),
                        1
                    );
                    this.treeDataCMLevels[0].children.forEach((child) => {
                        if (child.label === childProperty) {
                            if ("children" in child) {
                                child.children.forEach((chi) => {
                                    if (chi.label !== chip) {
                                        this.initSelectedCMLevels.push(chi.label);
                                    }
                                });
                            }
                        }
                    });
                }
            }

            if (name == 'macc') {
                this.selected_macc_levels = [];
                if (this.initSelectedMaccLevels.indexOf(chip) === -1) {
                    this.initSelectedMaccLevels = [];
                    this.treeDataMaccLevels[0].children.forEach((child) => {
                        if (child.label !== chip) {
                            this.initSelectedMaccLevels.push(child.label);
                            this.selected_macc_levels.push(child.label);
                        }
                    });
                } else {
                    this.initSelectedMaccLevels.splice(this.initSelectedMaccLevels.indexOf(chip), 1);
                    this.selected_macc_levels.splice(this.selected_macc_levels.indexOf(chip), 1);
                }
            }

            if (name == "pathway") {
                this.select_pathway.splice(this.select_pathway.indexOf(chip), 1);
                this.selected_pathway_data.splice(
                    this.selected_pathway_data.indexOf(chip),
                    1
                );
            }
        },
        customFormatter(selected_date) {
            return moment(selected_date).format("DD-MMM-YYYY");
        },
        clearDateFilter() {
            this.selected_date = null
        },
        showTreeBox() {
            return this.treeDataCity
        },
        convertType(type) {
            let newtype = "";
            newtype = type.toLowerCase();
            return newtype;
        },
        datatoTimestamp(stringyDate) {
            var dateyDate = new Date(this.timetoTimestamp(stringyDate));
            var ms = dateyDate.valueOf();
            return ms;
        },
        mapSearchSpoc(placement) {
            let obj = placement[0];
            let objindex = placement[1];
            let objlength = obj.dynamicSpoc.length / 2;
            let mapObj = [];
            for (let i = 1; i <= objlength; i++) {
                mapObj.push({
                    sequence: i,
                    data: [],
                });
            }
            let count = 0;
            let map = 0;
            let mapname = 0;
            obj.dynamicSpoc.forEach((spocstat, index) => {
                if (count === 2) {
                    count = 0;
                    map = map + 1;
                }
                mapObj[map].data.push(spocstat);
                if (index === objindex) {
                    mapname = map;
                }
                count = count + 1;
            });
            let spoc = this.foundspoc(mapname);
            return spoc;
        },
        foundspoc(spocindex) {
            let spocarr = [];
            this.tableHeaders.forEach((header, index) => {
                if (index > 2) {
                    spocarr.push(header);
                }
            });
            return spocarr[spocindex].header;
        }
    }
}
</script>
<style type="scss">
.pathway_dropdown .vs-dropdown--menu {
    width: 250px !important;
}

.pathway-filter-card .vx-card__collapsible-content .vx-card__body {
    padding: 0px !important;
}

.pathway-filter-card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.ele-side-head {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}

.pathway-filter-card .vx-card__collapsible-content .vx-card__body {
    padding: 0px !important;
}

.pathway-filter-card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.filter {
    margin-left: 4px;
}

.tree-drop {
    margin-top: 0.8%;
}

.tree-drop#city>.vs-con-dropdown {
    background: #eae5fa;
    border: 1px solid #a394d3;
}

.tree-drop#spoc>.vs-con-dropdown {
    background: #c3edfa;
    border: 1px solid #6cc8e6;
}

.tree-drop#date>.vs-con-dropdown {
    background: #fffbd5;
    border: 1px solid #e4d650;
    border-radius: 10px;
}

.tree-drop#date .vue-treeselect--open {
    border: 1px solid #e4d650;
    border-radius: 10px;
}

.tree-drop#lvl>.vs-con-dropdown {
    background: #d5ffd9;
    border: 1px solid #5ac765;
    border-radius: 10px;
}

.tree-drop#gm>.vs-con-dropdown {
    background: #d5fff5;
    border: 1px solid #35d6af;
    border-radius: 10px;
}

.tree-drop#eligible>.vs-con-dropdown {
    background: #FFD5D5;
    border: 1px solid #EE7B7B;
    border-radius: 10px;
}

.tree-drop#card2>.vs-con-dropdown {
    border: 1px solid #0044ba;
    width: 280.89px;
    height: 44.47px;
    display: flex;
    border-radius: 15px;
    align-items: center;
    padding-inline: 10px;
}

.tree-drop>.vs-con-dropdown {
    width: 90%;
    min-height: 32px;
    border-radius: 10px;
    padding-inline: 5px;
    color: #000000;
    display: flex !important;
    align-items: center;
}

.tree-drop>div>.reportrange-text {
    background: #FFFBD5 !important;
    border: 1px solid #E4D650 !important;
    width: 94% !important;
    margin-left: 4%;
    border-radius: 10px;
}

.tree-drop#elgibility_status>.vs-con-dropdown {
    background: #f1ad78;
    border: 1px solid #F38630;
    border-radius: 10px;
}

.a-icon {
    outline: none;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

i {
    font-size: 18px;
}

.vs-dropdown--menu {
    width: 300px;
}

.a-iconn {
    color: #000;
    font-weight: 400;
    font-size: 12px;
    width: 99%;
    display: flex;
    justify-content: flex-end !important;
}

.a-iconn>.material-icons {
    margin-left: auto;
}

.ele-inside-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.ele-inside-text>i {
    color: rgba(0, 0, 0, 0.4);
}

.criteria_chip {
    border-radius: 5px;
}

.criteria_chip .vs-chip--close {
    background: transparent !important;
    color: #000000;
}

.criteria_chip .vs-chip--text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: #000000;
}

.criteria {
    background: #ffffff;
    min-height: 119.65px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.criteria_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}

.no-data-found {
    display: flex;
    justify-content: center;
    align-content: center;
    font-family: 'Montserrat';
    letter-spacing: 0.3px;
    font-size: 20px;
    font-weight: 900;
    margin-top: 25px
}

.my_date_picker>div input {
    width: 95%;
}

.my_date_picker>div input {
    background: #ffffff;
    -webkit-padding-start: 9%;
    display: block;
    border-radius: 5px;
    min-height: 30px !important;
    max-height: 32px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    color: #000000 !important;
    border-radius: 10px;
}

.df_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

@media only screen and (min-width: 1800px) {
    .filter {
        margin-left: 2.8%;
    }
}

@media only screen and (min-width: 1725px) {
    .filter {
        margin-left: 1.4%;
    }
}
</style>